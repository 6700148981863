import {State} from "../store/rootReducer";
import {connect, ConnectedProps} from "react-redux";
import React, {useCallback, useEffect, useRef} from "react";
import {Map, TileLayer} from 'react-leaflet';
import {LatLng} from "leaflet";
import 'leaflet/dist/leaflet.css';
import 'leaflet/dist/images/marker-icon.png';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {selectQuadrant, setMapBounds} from "../store/map/mapActions";
import SensorStatisticsLayer from "./SensorStatisticsLayer";


const useStyles = makeStyles({
    root: {
        position: 'relative',
        height: '100%',
        width: '100%',
        background: 'darkgray',
    },
});
const mapState = (state: State) => ({});
const mapDispatch = {
    setMapBounds, selectQuadrant
};
const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;
const MapView = ({setMapBounds, selectQuadrant}: ReduxProps) => {
    const classes = useStyles();
    const map = useRef<Map>(null);
    const position = new LatLng(48.137222, 11.575556);
    const handleViewportChanged = useCallback(() => {
        if (map.current) {
            const mapBounds = map.current.leafletElement.getBounds();
            setMapBounds({
                left: mapBounds.getWest(),
                bottom: mapBounds.getSouth(),
                right: mapBounds.getEast(),
                top: mapBounds.getNorth(),
            })
        }
    }, [map, setMapBounds]);
    useEffect(() => {
        // get initial bounds
        handleViewportChanged();
    }, [handleViewportChanged]);

    const handleClick = () => {
        selectQuadrant(null);
    };

    return <Map ref={map} center={position} zoom={13} className={classes.root}
                onclick={handleClick}
                onViewportChanged={handleViewportChanged}>
        <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
        />
        <SensorStatisticsLayer/>
    </Map>;
};

export default connector(MapView);