import {ActionTypes} from "./actionTypes";

interface AppState {
    apiUrl: string
    isLoggedIn: boolean
}

const initialState: AppState = {
    apiUrl: 'http://localhost:8083',
    isLoggedIn: false,
};

export function appReducer(
    state = initialState,
    action: ActionTypes,
): AppState {
    // switch (action.type) {
    // }
    return state
}
