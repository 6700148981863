import React, {Component} from "react";
import {CallbackComponent} from "redux-oidc";
import userManager from "./userManager";
import {withRouter, RouteComponentProps} from "react-router-dom";
import {User} from "oidc-client";


class LoginCallbackPage extends Component<RouteComponentProps> {
    render() {
        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={(user: User) => this.props.history.push(user.state)}
                errorCallback={error => {
                    this.props.history.push("/");
                    console.error(error);
                }}
            >
                <div>Redirecting...</div>
            </CallbackComponent>
        );
    }

}

export default withRouter(LoginCallbackPage)
