import {ThunkAction} from "redux-thunk";
import {State} from "../store/rootReducer";
import {API_BASE_URL} from "../config";
import {NotificationType} from "../store/notification/notificationTypes";
import {pushNotification} from "../store/notification/notificationActions";
import {ActionTypes} from "../store/actionTypes";
import {ApiKey} from "../model/ApiKey";
import {Sensor, SensorEvent, SensorStatisticsItem} from "../model/SensorData";
import moment from "moment";
import {
    fetchedApiKeys, fetchedSensorEvents,
    fetchedSensors,
    fetchedSensorStatistics,
    fetchingApiKeys, fetchingSensorEvents,
    fetchingSensors,
    fetchingSensorStatistics
} from "../store/data/dataActions";
import {getAccessToken} from "../store/oidc/oidcSelectors";
import {GeoBoundingBox} from "../model/Geo";
import {getMapBounds, getSelectedQuadrantGeohash} from "../store/map/mapSelectors";
import {getSensorEventsNextPageLink, getSensorsNextPageLink} from "../store/data/dataSelectors";
import {getSelectedSensor} from "../store/sensor/sensorSelectors";


export const thunkFetchApiKeys = (): ThunkAction<void, State, unknown, ActionTypes> => async (dispatch, getState) => {
    dispatch(fetchingApiKeys());
    try {
        const response = await fetch(`${API_BASE_URL}/api-keys`, {
            headers: {Authorization: `Bearer ${getAccessToken(getState())}`},
        });
        checkResponse(response);
        const apiKeys = responseToApiKeys(await response.json() as Paths.Map433AdapterRestManagementGetApiKeys.Responses.$200);
        dispatch(fetchedApiKeys(apiKeys))
    } catch (err) {
        console.warn('Error fetching api keys', err);
        dispatch(pushNotification(NotificationType.ERROR, 'Could not fetch api keys'));
        dispatch(fetchedApiKeys([]))
    }
};

export const thunkCreateApiKey = (): ThunkAction<void, State, unknown, ActionTypes> => async (dispatch, getState) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api-keys`, {
            method: 'POST',
            headers: {Authorization: `Bearer ${getAccessToken(getState())}`},
        });
        checkResponse(response);
        dispatch(pushNotification(NotificationType.SUCCESS, 'New api key created'));
    } catch (err) {
        console.warn('Error creating api key', err);
        dispatch(pushNotification(NotificationType.ERROR, 'Could not create api key'));
    }
    dispatch(thunkFetchApiKeys())
};

export const thunkDeleteApiKey = (apiKeyId: string): ThunkAction<void, State, unknown, ActionTypes> => async (dispatch, getState) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api-keys/${apiKeyId}`, {
            method: 'DELETE',
            headers: {Authorization: `Bearer ${getAccessToken(getState())}`},
        });
        checkResponse(response);
        dispatch(pushNotification(NotificationType.SUCCESS, 'Api key deleted'));
    } catch (err) {
        console.warn('Error deleting api key', err);
        dispatch(pushNotification(NotificationType.ERROR, 'Could not create api key'));
    }
    dispatch(thunkFetchApiKeys())
};

export const thunkFetchSensorStatistics = (): ThunkAction<void, State, unknown, ActionTypes> => async (dispatch, getState) => {
    dispatch(fetchingSensorStatistics());
    try {
        const bbox = getBoundingBoxQueryString(getMapBounds(getState()));
        const response = await fetch(`${API_BASE_URL}/sensor-statistics?bbox=${bbox}`);
        checkResponse(response);
        const sensorStatistics = responseToSensorStatistics(await response.json() as Paths.Map433AdapterRestQueryGetSensorStatistics.Responses.$200);
        dispatch(fetchedSensorStatistics(sensorStatistics))
    } catch (err) {
        console.warn('Error fetching sensor statistics', err);
        dispatch(pushNotification(NotificationType.ERROR, 'Could not fetch sensor statistics'));
        dispatch(fetchedApiKeys([]))
    }
};

export const thunkFetchSensors = (nextPage: boolean = false): ThunkAction<void, State, unknown, ActionTypes> => async (dispatch, getState) => {
    dispatch(fetchingSensors());
    try {
        let fetchUrl = null;
        if (nextPage) {
            fetchUrl = getSensorsNextPageLink(getState());
        } else {
            const geohash = getSelectedQuadrantGeohash(getState());
            if (geohash) {
                fetchUrl = `${API_BASE_URL}/sensors?geohash=${geohash}&embed=last_event`;
            }
        }
        if (fetchUrl) {
            const response = await fetch(fetchUrl);
            checkResponse(response);
            const responseData = await response.json() as Paths.Map433AdapterRestQueryGetSensors.Responses.$200;
            const sensors = responseToSensors(responseData);
            const nextPageLink = responseData._links?.next?.href || null;
            dispatch(fetchedSensors(sensors, nextPageLink, nextPage))
        } else {
            dispatch(fetchedSensors([], null, nextPage))
        }
    } catch (err) {
        console.warn('Error fetching sensors', err);
        dispatch(pushNotification(NotificationType.ERROR, 'Could not fetch sensors'));
        dispatch(fetchedSensors([], null, nextPage))
    }
};

export const thunkFetchSensorEvents = (nextPage: boolean = false): ThunkAction<void, State, unknown, ActionTypes> => async (dispatch, getState) => {
    dispatch(fetchingSensorEvents());
    try {
        let fetchUrl = null;
        if (nextPage) {
            fetchUrl = getSensorEventsNextPageLink(getState());
        } else {
            const selectedSensor = getSelectedSensor(getState());
            if (selectedSensor) {
                fetchUrl = selectedSensor.events_link + '?limit=100';  // TODO maybe adapt in backend
            }
        }
        if (fetchUrl) {
            const response = await fetch(fetchUrl);
            checkResponse(response);
            const responseData = await response.json() as Paths.Map433AdapterRestQueryGetEvents.Responses.$200;
            const events = responseData.items!.map(responseToEvent);
            const nextPageLink = responseData._links?.next?.href || null;
            dispatch(fetchedSensorEvents(events, nextPageLink, nextPage))
        } else {
            dispatch(fetchedSensorEvents([], null, nextPage))
        }
    } catch (err) {
        console.warn('Error fetching sensor events', err);
        dispatch(pushNotification(NotificationType.ERROR, 'Could not fetch sensor events'));
        dispatch(fetchedSensorEvents([], null, nextPage))
    }
};

function getBoundingBoxQueryString(bounding_box: GeoBoundingBox): string {
    return [bounding_box.left, bounding_box.bottom, bounding_box.right, bounding_box.top].join(',')
}

function responseToApiKeys(json: Paths.Map433AdapterRestManagementGetApiKeys.Responses.$200): Array<ApiKey> {
    return json.items!.map((item) => ({
        id: item.id,
        created_at: moment(item.created_at),
        key: item.key,
    }))
}

function responseToSensorStatistics(json: Paths.Map433AdapterRestQueryGetSensorStatistics.Responses.$200): Array<SensorStatisticsItem> {
    return json.items!.map((item) => ({
        center: {...item.center},
        bounding_box: {...item.bounding_box},
        geohash: item.geohash,
        count: item.count,
    }));
}

function responseToSensors(json: Paths.Map433AdapterRestQueryGetSensors.Responses.$200): Array<Sensor> {
    return json.items!.map((item) => ({
        id: item.id,
        label: item.label,
        metadata: {...item.metadata},
        last_event: responseToEvent(item._embedded!.last_event!),
        events_link: item._links!.events.href!,
    }));
}

function responseToEvent(json: Components.Schemas.Event): SensorEvent {
    return {
        id: json.id,
        sensor_id: json.sensor_id,
        occurred_at: moment(json.occurred_at),
        position: {...json.position},
        sensor_data: {...json.sensor_data}
    }
}


function checkResponse(response: Response): Response {
    if (200 <= response.status && response.status < 300) {
        return response;
    } else {
        throw Error(`Request failed: ${response.status} ${response.statusText}`)
    }
}
