import React from "react";
import {Box, Button, CircularProgress, Paper, Theme, Typography} from "@material-ui/core";
import {State} from "../store/rootReducer";
import {connect, ConnectedProps} from "react-redux";
import {getSelectedSensor} from "../store/sensor/sensorSelectors";
import {SensorEventDetailView, SensorMetadataView} from "./SensorDataComponents";
import {makeStyles} from "@material-ui/core/styles";
import {TimeSeriesChart} from "../graph/TimeSeriesChart";
import {mapEventDataToGraphs} from "../graph/graphDataMapper";
import {getIsFetchingSensorEvents, getSensorEvents, getSensorEventsNextPageLink} from "../store/data/dataSelectors";
import {thunkFetchSensorEvents} from "../api/apiClient";
import {selectSensor} from "../store/sensor/sensorActions";

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    inner: {
        margin: theme.spacing(1),
    },
    contentWrapper: {
        padding: theme.spacing(2),
    },
    graphWrapper: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));
const mapState = (state: State) => ({
    sensor: getSelectedSensor(state),
    sensorEvents: getSensorEvents(state),
    hasNextEventsPage: !!getSensorEventsNextPageLink(state),
    isFetchingSensorEvents: getIsFetchingSensorEvents(state),
});
const mapDispatch = {
    fetchSensorEvents: thunkFetchSensorEvents,
    selectSensor: selectSensor,
};
const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

interface Props {
    className: string;
}

const SensorDetailView = ({sensor, sensorEvents, selectSensor, hasNextEventsPage, fetchSensorEvents, isFetchingSensorEvents, className}: ReduxProps & Props) => {
    const styles = useStyles();

    if (!sensor) {
        return null;
    }

    const handleBackClick = () => {
        selectSensor(null);
    };

    const loadNextEventsPage = () => {
        fetchSensorEvents(true);
    };

    const graphDataArray = mapEventDataToGraphs(sensorEvents);

    return <>
        <Box className={className + ' ' + styles.root}>
            <Paper className={styles.inner}>
                <Button onClick={handleBackClick} variant={'outlined'}>Back</Button>
                <Box className={styles.contentWrapper}>
                    <Typography variant={'h5'} gutterBottom>{sensor.label}</Typography>
                    <Box mb={1} mt={2}>
                        <Typography variant={'h6'}>Metadata</Typography>
                        <SensorMetadataView metadata={sensor.metadata}/>
                    </Box>
                    <Box mb={1} mt={2}>
                        <Typography variant={'h6'}>Last Event</Typography>
                        <SensorEventDetailView event={sensor.last_event}/>
                    </Box>
                </Box>
            </Paper>
            {graphDataArray.map((graphData, i) =>
                <Paper key={i} className={styles.inner}>
                    <Box className={styles.graphWrapper}>
                        <Typography>{graphData.name}</Typography>
                        <TimeSeriesChart graphData={graphData}/>
                    </Box>
                    {hasNextEventsPage &&
                    <Button onClick={loadNextEventsPage} variant={'outlined'} disabled={isFetchingSensorEvents}>
                        Load more
                        {isFetchingSensorEvents &&
                        <Box clone={true} ml={2}><CircularProgress size={20} thickness={5} color={'inherit'}/></Box>}
                    </Button>}
                </Paper>
            )}
        </Box>
    </>
};

export default connector(SensorDetailView);
