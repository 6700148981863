import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './App.css';
import {MuiThemeProvider} from "@material-ui/core/styles";
import {THEME} from "./styles/theme";
import {ApiKeyAdminPage} from "./api-key-admin/ApiKeyAdminPage";
import MainView from "./main/MainView";
import MenuBar from "./menu/MenuBar";
import LoginCallbackPage from "./login/LoginCallbackPage";
import Notifier from "./notifications/Notifier";
import {SnackbarProvider} from "notistack";

export const PATH_API_KEY_ADMIN = '/api-key-admin';
export const PATH_MAIN = '/';

function App() {
    return (
        <Router>
            <MuiThemeProvider theme={THEME}>
                <SnackbarProvider maxSnack={5}>
                    <div className="App">
                        <Notifier/>
                        <div className="App-menu-bar">
                            <MenuBar/>
                        </div>
                        <div className="App-main">
                            <Switch>
                                <Route exact path={'/redirect'} component={LoginCallbackPage}/>
                                <Route exact path={PATH_API_KEY_ADMIN} component={ApiKeyAdminPage}/>
                                <Route path={PATH_MAIN} component={MainView}/>
                                {/*<Route path={linkToEditor()} component={Editor} />*/}
                            </Switch>
                        </div>
                    </div>
                </SnackbarProvider>
            </MuiThemeProvider>
        </Router>
    );
}

export default App;
