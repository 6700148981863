import React, {FunctionComponent} from "react";
import {connect, ConnectedProps} from "react-redux";
import {State} from "../store/rootReducer";
import {getIsUserLoggedIn} from "../store/oidc/oidcSelectors";
import {Button, Typography} from "@material-ui/core";
import {signinRedirect} from "./userManager";
import {useHistory} from "react-router-dom";


const mapState = (state: State) => ({
    isUserLoggedIn: getIsUserLoggedIn(state),
});
const mapDispatch = {};
const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

const LoginWrapper: FunctionComponent<ReduxProps> = ({isUserLoggedIn, children} ) => {
    const history = useHistory();

    const handleLogin = () => {
        signinRedirect(history.location.pathname);
    };

    if (!isUserLoggedIn) {
        return <>
            <Typography variant={'h2'} component={'h1'} gutterBottom>Login required</Typography>
            <Typography variant="body1" gutterBottom>The page you want to access requires login.</Typography>
            <Button variant={'contained'} onClick={handleLogin} color={'primary'}>Login</Button>
        </>
    }

    return <>
        {children}
    </>;
};

export default connector(LoginWrapper);

