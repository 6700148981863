import {State} from "../rootReducer";
import {Sensor} from "../../model/SensorData";


export const getSelectedSensorId = (state: State): string | null => state.sensor.selectedSensorId;

export const getSelectedSensor = (state: State): Sensor | null => {
    const sensorId = getSelectedSensorId(state);
    if (sensorId) {
        const result = state.data.sensors.find(sensor => sensor.id === sensorId);
        return result || null;
    }
    return null;
};

export const getIsCurrentDataFilterEnabled = (state: State) => state.sensor.isCurrentDataFilterEnabled;