import {State} from "../rootReducer";
import {ApiKey} from "../../model/ApiKey";
import {Sensor, SensorEvent, SensorStatisticsItem} from "../../model/SensorData";


export const getApiKeys = (state: State): Array<ApiKey> => state.data.apiKeys;
export const getSensorStatistics = (state: State): Array<SensorStatisticsItem> => state.data.sensorStatistics;
export const getSensors = (state: State): Array<Sensor> => state.data.sensors;
export const getSensorsNextPageLink = (state: State): string | null => state.data.sensorsNextPageLink;
export const getIsFetchingSensors = (state: State): boolean => state.data.fetchingSensors;
export const getSensorEvents = (state: State): Array<SensorEvent> => state.data.sensorEvents;
export const getSensorEventsNextPageLink = (state: State): string | null => state.data.sensorEventsNextPageLink;
export const getIsFetchingSensorEvents = (state: State): boolean => state.data.fetchingSensorEvents;
