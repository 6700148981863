import {State} from "../store/rootReducer";
import {connect, ConnectedProps} from "react-redux";
import {getMapBounds, getSelectedQuadrantGeohash} from "../store/map/mapSelectors";
import React, {useEffect} from "react";
import {thunkFetchSensorEvents, thunkFetchSensors, thunkFetchSensorStatistics} from "../api/apiClient";
import {getSelectedSensor} from "../store/sensor/sensorSelectors";

const mapState = (state: State) => ({
    mapBounds: getMapBounds(state),
    selectedQuadrantGeohash: getSelectedQuadrantGeohash(state),
    selectedSensor: getSelectedSensor(state),
});
const mapDispatch = {
    fetchStatistics: thunkFetchSensorStatistics,
    fetchSensors: thunkFetchSensors,
    fetchSensorEvents: thunkFetchSensorEvents,
};
const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

const DataFetcher = ({mapBounds, selectedQuadrantGeohash, selectedSensor, fetchStatistics, fetchSensors, fetchSensorEvents}: ReduxProps) => {
    useEffect(() => {
        fetchStatistics();
    }, [mapBounds, fetchStatistics]);
    useEffect(() => {
        fetchSensors();
    }, [selectedQuadrantGeohash, fetchSensors]);
    useEffect(() => {
        fetchSensorEvents();
    }, [selectedSensor, fetchSensorEvents]);

    return <></>;
};

export default connector(DataFetcher);