import {ThunkAction} from "redux-thunk";
import {State} from "./store/rootReducer";
import {Action} from "redux";


export const initialize = () : ThunkAction<void, State, void, Action<any>> => async (dispatch, getState) => {
    // await dispatch(fetchConfig());
    // if (getAuthorizationType(getState()) === AuthorizationType.NONE) {
    //     return dispatch(fetchDevices());
    // }
};