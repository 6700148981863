import {ActionTypes} from "../actionTypes";
import {MAP_SELECT_QUADRANT, MAP_SET_BOUNDS} from "./mapActionTypes";
import {GeoBoundingBox, GeoQuadrant} from "../../model/Geo";


export const setMapBounds = (payload: GeoBoundingBox): ActionTypes => ({
    type: MAP_SET_BOUNDS,
    payload: payload,
});

export const selectQuadrant = (payload: GeoQuadrant | null): ActionTypes => ({
    type: MAP_SELECT_QUADRANT, payload,
});

