import React, {useEffect} from "react";
import {connect, ConnectedProps} from "react-redux";
import {State} from "../store/rootReducer";
import {getIsUserLoggedIn} from "../store/oidc/oidcSelectors";
import {thunkCreateApiKey, thunkDeleteApiKey, thunkFetchApiKeys} from "../api/apiClient";
import {ApiKey} from "../model/ApiKey";
import {Button, IconButton, List, ListItem, ListItemText} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {getApiKeys} from "../store/data/dataSelectors";
import {NotificationType} from "../store/notification/notificationTypes";
import {pushNotification} from "../store/notification/notificationActions";


const mapState = (state: State) => ({
    isUserLoggedIn: getIsUserLoggedIn(state),
    apiKeys: getApiKeys(state),
});
const mapDispatch = {
    fetchApiKeys: thunkFetchApiKeys,
    createApiKey: thunkCreateApiKey,
    deleteApiKey: thunkDeleteApiKey,
    pushNotification: pushNotification,
};
const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

const ApiKeyListView = ({isUserLoggedIn, apiKeys, fetchApiKeys, createApiKey, deleteApiKey, pushNotification}: ReduxProps) => {
    useEffect(() => {
        fetchApiKeys()
    }, [fetchApiKeys]);

    const handleCopy = (apiKey: ApiKey) => {
        navigator.clipboard.writeText(apiKey.key);
        pushNotification(NotificationType.INFO, 'Api key copied to clipboard');
    };

    const handleDelete = (apiKey: ApiKey) => {
        deleteApiKey(apiKey.id);
    };

    const apiKeyListItem = (apiKey: ApiKey) => <ListItem
        key={apiKey.id}
    >
        <IconButton aria-label="Delete"
                    onClick={() => handleDelete(apiKey)}>
            <DeleteIcon/>
        </IconButton>
        <IconButton aria-label="Copy"
                    onClick={() => handleCopy(apiKey)}>
            <FileCopyIcon/>
        </IconButton>
        <ListItemText
            primary={apiKey.key} secondary={`created: ${apiKey.created_at.format()}`}
        />
    </ListItem>;

    apiKeys.sort((a, b) => (a.created_at.valueOf() - b.created_at.valueOf()));

    return <>
        <List>
            {apiKeys.map(apiKeyListItem)}
        </List>
        <Button variant={'contained'} onClick={createApiKey} color={'primary'}>Generate Api Key</Button>
        <Button variant={'contained'} onClick={fetchApiKeys}>Refresh</Button>
    </>
};

export default connector(ApiKeyListView);
