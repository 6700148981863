import {State} from "../store/rootReducer";
import {connect, ConnectedProps} from "react-redux";
import {getSensorStatistics} from "../store/data/dataSelectors";
import React, {useState} from "react";
import {Marker, Rectangle} from "react-leaflet";
import {boundingBoxToLeaflet, positionToLeaflet} from "./mapConverters";
import L, {LeafletMouseEvent} from "leaflet";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {selectQuadrant} from "../store/map/mapActions";
import {getSelectedQuadrantGeohash} from "../store/map/mapSelectors";


const useStyles = makeStyles({
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inner: {
        fontSize: '30px',
    },
    quadrant: {
        fill: 'rgba(128, 128, 255, .3)',
        stroke: 'rgba(128, 128, 255, .4)',
    },
    quadrantSelected: {
        fill: 'rgba(255, 128, 255, .5)',
        stroke: 'rgba(255, 128, 255, .6)',
    }
});
const mapState = (state: State) => ({
    sensorStatistics: getSensorStatistics(state),
    selectedQuadrantGeohash: getSelectedQuadrantGeohash(state),
});
const mapDispatch = {
    selectQuadrant,
};
const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

const SensorStatisticsLayer = ({sensorStatistics, selectedQuadrantGeohash, selectQuadrant}: ReduxProps) => {
    const styles = useStyles();
    const [hoveredQuadrantGeohash, setHoveredQuadrantGeohash] = useState<string | null>(null);

    const enterQuadrant = (geohash: string) => () => {
        setHoveredQuadrantGeohash(geohash);
    };
    const leaveQuadrant = (geohash: string) => () => {
        if (hoveredQuadrantGeohash === geohash) {
            setHoveredQuadrantGeohash(null);
        }
    };
    const clickQuadrant = (geohash: string) => (event: LeafletMouseEvent) => {
        selectQuadrant({geohash});
        L.DomEvent.stopPropagation(event);
    };

    return <>
        {
            sensorStatistics.map((item, index) => {
                const icon = L.divIcon({
                    html: `<div class=${styles.inner}>${item.count}</div>`,
                    iconSize: [50, 50],
                    className: styles.icon
                });
                const isSelected = item.geohash === hoveredQuadrantGeohash || item.geohash === selectedQuadrantGeohash;
                return <Rectangle
                    key={index}
                    onmouseover={enterQuadrant(item.geohash)}
                    onmouseout={leaveQuadrant(item.geohash)}
                    onclick={clickQuadrant(item.geohash)}
                    opacity={isSelected ? 0.5 : 0.3}
                    fillOpacity={isSelected ? 0.3 : 0.1}
                    bounds={boundingBoxToLeaflet(item.bounding_box)}
                >
                    <Marker
                        position={positionToLeaflet(item.center)}
                        icon={icon}
                        onmouseover={enterQuadrant(item.geohash)}
                        onmouseout={leaveQuadrant(item.geohash)}
                        onClick={clickQuadrant(item.geohash)}
                    />
                </Rectangle>
            })
        }
    </>
};

export default connector(SensorStatisticsLayer);
