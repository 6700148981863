import {createUserManager} from 'redux-oidc';
import {OIDC_AUTHORITY, OIDC_CLIENT_ID, OIDC_REDIRECT_URI} from "../config";

const userManagerConfig = {
    client_id: OIDC_CLIENT_ID,
    redirect_uri: OIDC_REDIRECT_URI,
    //response_type: 'token id_token',
    response_type: 'token',
    scope: 'map433/api-keys',
    // scope: 'openid profile https://www.googleapis.com/auth/youtube.readonly',
    authority: OIDC_AUTHORITY,
    // silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
    // automaticSilentRenew: true,
    // filterProtocolClaims: true,
    loadUserInfo: true,
};

console.log(`authority: ${process.env.REACT_APP_AUTHORITY}`);

const userManager = createUserManager(userManagerConfig);

export const signinRedirect = (currentLocation: string) => {
    userManager.signinRedirect({state: currentLocation})
};

export default userManager;