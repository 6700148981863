import rootReducer, {State} from "./store/rootReducer";
import {ActionTypes} from "./store/actionTypes";
import {configureStore} from "@reduxjs/toolkit";
import {loadUser} from "redux-oidc";
import userManager from "./login/userManager";
import thunk from "redux-thunk";

export const store = configureStore<State, ActionTypes>({
    reducer: rootReducer,
    middleware: [thunk],
});
loadUser(store, userManager);
