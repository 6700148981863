import {ActionTypes} from "../actionTypes";
import {GeoBoundingBox, GeoQuadrant} from "../../model/Geo";
import {MAP_SELECT_QUADRANT, MAP_SET_BOUNDS} from "./mapActionTypes";

interface MapState {
    bounds: GeoBoundingBox,
    selectedQuadrant: GeoQuadrant | null,
}

const initialState: MapState = {
    bounds: {left: 10, bottom: 48, right: 11, top: 49},
    selectedQuadrant: null,
};

export function mapReducer(
    state = initialState,
    action: ActionTypes,
): MapState {
    switch (action.type) {
        case MAP_SET_BOUNDS:
            return {
                ...state,
                bounds: action.payload,
            };
        case MAP_SELECT_QUADRANT:
            return {
                ...state,
                selectedQuadrant: action.payload,
            };
    }
    return state
}
