import {HumidityValue, PressureValue, TemperatureValue} from "../model/SensorData";


export const formatTemperature = (value: TemperatureValue): string =>
    `${getTemperatureValue(value).toFixed(1)} ${getTemperatureUnit()}`;
export const getTemperatureValue = (value: TemperatureValue): number => value.C;
export const getTemperatureUnit = (): string => ('°C');

export const formatHumidity = (value: HumidityValue): string =>
    `${getHumidityValue(value).toFixed(0)} ${getHumidityUnit()}`;
export const getHumidityValue = (value: HumidityValue): number => value.percent;
export const getHumidityUnit = (): string => ('%');

export const formatPressure = (value: PressureValue): string =>
    `${getPressureValue(value).toFixed(0)} ${getPressureUnit()}`;
export const getPressureValue = (value: PressureValue): number => value.Pa * 1e-5;
export const getPressureUnit = (): string => ('bar');



