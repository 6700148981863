import {ActionTypes} from "../actionTypes";
import {SENSOR_SELECT_SENSOR, SENSOR_SET_CURRENT_DATA_FILTER} from "./sensorActionTypes";

export interface SensorState {
    selectedSensorId: string | null;
    isCurrentDataFilterEnabled: boolean;
}

const initialState: SensorState = {
    selectedSensorId: null,
    isCurrentDataFilterEnabled: false,
};

export function sensorReducer(
    state = initialState,
    action: ActionTypes,
): SensorState {
    switch (action.type) {
        case SENSOR_SELECT_SENSOR:
            return {
                ...state,
                selectedSensorId: action.payload.sensorId,
            };
        case SENSOR_SET_CURRENT_DATA_FILTER:
            return {
                ...state,
                isCurrentDataFilterEnabled: action.payload.isFilterEnabled,
            };
    }
    return state
}
