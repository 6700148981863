import React from 'react';
import MapView from "../map/MapView";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DataFetcher from "./DataFetcher";
import SensorListView from "../sensors/SensorListView";
import {Theme} from "@material-ui/core";
import SensorDetailView from "../sensors/SensorDetailView";
import {State} from "../store/rootReducer";
import {getSelectedSensor} from "../store/sensor/sensorSelectors";
import {connect, ConnectedProps} from "react-redux";
import {getSensors} from "../store/data/dataSelectors";


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        maxHeight: '100%',
        height: '100%',
        width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
        map: {
            flexGrow: 1,
        },
        sensorListView: {
            flexGrow: 1,
        },
        sensorDetailView: {
            flexGrow: 1,
        },
        notSelected: {
            maxWidth: 0,
        },
    },
    [theme.breakpoints.up('md')]: {
        map: {
            flexGrow: 1,
        },
        sensorListView: {
            minWidth: '300px',
        },
        sensorDetailView: {
            minWidth: '450px',
        },
    },
    sensorListView: {
        position: 'relative',
        overflowY: 'auto'
    },
    sensorDetailView: {
        position: 'relative',
        overflowY: 'auto'
    },
}));

const mapState = (state: State) => ({
    isSensorListVisible: getSensors(state).length > 0,
    isSensorSelected: !!getSelectedSensor(state),
});
const mapDispatch = {
};
const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

const MainView = ({isSensorListVisible, isSensorSelected}: ReduxProps) => {
    const classes = useStyles();
    const isMapSelected = !(isSensorListVisible || isSensorSelected);
    const isSensorListViewSelected = isSensorListVisible && !isSensorSelected;
    const isSensorDetailViewSelected = isSensorSelected;
    return (
        <div className={classes.root}>
            <DataFetcher/>
            <div className={classes.map + ' ' + (!isMapSelected && classes.notSelected)}>
                <MapView/>
            </div>
            <SensorListView className={classes.sensorListView + ' ' + (!isSensorListViewSelected && classes.notSelected)}/>
            <SensorDetailView className={classes.sensorDetailView + ' ' + (!isSensorDetailViewSelected && classes.notSelected)}/>
        </div>
    );
};

export default connector(MainView);
