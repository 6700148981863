import {ApiKey} from "../../model/ApiKey";
import {ActionTypes} from "../actionTypes";
import {
    DATA_FETCHED_API_KEYS, DATA_FETCHED_SENSOR_EVENTS,
    DATA_FETCHED_SENSOR_STATISTICS, DATA_FETCHED_SENSORS,
    DATA_FETCHING_API_KEYS, DATA_FETCHING_SENSOR_EVENTS,
    DATA_FETCHING_SENSOR_STATISTICS,
    DATA_FETCHING_SENSORS
} from "./dataActionTypes";
import {Sensor, SensorEvent, SensorStatisticsItem} from "../../model/SensorData";


export const fetchingApiKeys = (): ActionTypes => ({
    type: DATA_FETCHING_API_KEYS,
});

export const fetchedApiKeys = (payload: Array<ApiKey>): ActionTypes => ({
    type: DATA_FETCHED_API_KEYS, payload,
});

export const fetchingSensorStatistics = (): ActionTypes => ({
    type: DATA_FETCHING_SENSOR_STATISTICS,
});

export const fetchedSensorStatistics = (payload: Array<SensorStatisticsItem>): ActionTypes => ({
    type: DATA_FETCHED_SENSOR_STATISTICS, payload,
});

export const fetchingSensors = (): ActionTypes => ({
    type: DATA_FETCHING_SENSORS,
});

export const fetchedSensors = (items: Array<Sensor>, nextPageLink: string | null = null, append: boolean = false): ActionTypes => ({
    type: DATA_FETCHED_SENSORS, payload: {items, nextPageLink, append},
});

export const fetchingSensorEvents = (): ActionTypes => ({
    type: DATA_FETCHING_SENSOR_EVENTS,
});

export const fetchedSensorEvents = (items: Array<SensorEvent>, nextPageLink: string | null = null, append: boolean = false): ActionTypes => ({
    type: DATA_FETCHED_SENSOR_EVENTS, payload: {items, nextPageLink, append},
});

