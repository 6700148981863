import {GeoBoundingBox, GeoPosition} from "../model/Geo";
import {LatLngBoundsExpression, LatLngExpression} from "leaflet";

export function boundingBoxToLeaflet(bounding_box: GeoBoundingBox): LatLngBoundsExpression {
    return [
        [bounding_box.bottom, bounding_box.left],
        [bounding_box.top, bounding_box.right],
    ];
}

export function positionToLeaflet(position: GeoPosition): LatLngExpression {
    return [position.latitude, position.longitude];
}
