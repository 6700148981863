import {SensorEvent, SensorValue} from "../model/SensorData";
import {Moment} from "moment";
import {
    getHumidityUnit,
    getHumidityValue,
    getPressureUnit,
    getPressureValue,
    getTemperatureUnit,
    getTemperatureValue
} from "../sensors/sensorDataFormatter";

interface GraphDataPoint {
    key: Date,
    data: number,
}

export interface GraphData {
    name: string,
    data: Array<GraphDataPoint>,
    unit: string,
}

export function mapEventDataToGraphs(events: Array<SensorEvent>): Array<GraphData> {
    const graphs: Array<GraphData> = [];

    function addValue<T extends SensorValue>(name: string, occurredAt: Moment, value: T,
                                             valueExtractor: (value: T) => number, unitExtractor: () => string) {
        let graph = graphs.find(it => it.name === name);
        if (!graph) {
            graph = {name, data: [], unit: unitExtractor()};
            graphs.push(graph);
        }
        graph.data.push({
            key: occurredAt.toDate(),
            data: valueExtractor(value),
        })
    }

    events.forEach(event => {
        event.sensor_data.temperature && addValue('temperature', event.occurred_at, event.sensor_data.temperature, getTemperatureValue, getTemperatureUnit);
        event.sensor_data.humidity && addValue('humidity', event.occurred_at, event.sensor_data.humidity, getHumidityValue, getHumidityUnit);
        event.sensor_data.air_pressure && addValue('air_pressure', event.occurred_at, event.sensor_data.air_pressure, getPressureValue, getPressureUnit);
        event.sensor_data.tire_pressure && addValue('tire_pressure', event.occurred_at, event.sensor_data.tire_pressure, getPressureValue, getPressureUnit);
    });

    return graphs;
}