import {combineReducers} from 'redux'
// import {devicesReducer} from "./devices/devicesReducer"
// import {mapViewReducer} from "./mapView/mapViewReducer";
import {appReducer} from "./appReducer";
import {reducer as oidcReducer} from 'redux-oidc';
import {notificationReducer} from "./notification/notificationReducer";
import {dataReducer} from "./data/dataReducer";
import {mapReducer} from "./map/mapReducer";
import {sensorReducer} from "./sensor/sensorReducer";
// import {notificationReducer} from "./notification/notificationReducer";


const rootReducer = combineReducers({
    app: appReducer,
    oidc: oidcReducer,
    data: dataReducer,
    map: mapReducer,
    notifications: notificationReducer,
    sensor: sensorReducer,
});

export default rootReducer

export type State = ReturnType<typeof rootReducer>
