import React from "react";
import ApiKeyListView from "./ApiKeyListView";
import {Container, Typography} from "@material-ui/core";
import LoginWrapper from "../login/LoginWrapper";


export const ApiKeyAdminPage = () => {
    return <Container maxWidth="sm">
        <LoginWrapper>
            <Typography variant={'h2'} component={'h1'}>Api Keys</Typography>
            <ApiKeyListView/>
        </LoginWrapper>
    </Container>
};

