import {GeoBoundingBox, GeoQuadrant} from "../../model/Geo";

export const MAP_SET_BOUNDS = 'MAP_SET_BOUNDS';
export const MAP_SELECT_QUADRANT = 'MAP_SELECT_QUADRANT';

export interface SetBoundsAction {
    type: typeof MAP_SET_BOUNDS;
    payload: GeoBoundingBox;
}

export interface SelectQuadrantAction {
    type: typeof MAP_SELECT_QUADRANT;
    payload: GeoQuadrant | null,
}


export type MapActionTypes = SetBoundsAction | SelectQuadrantAction