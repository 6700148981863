import React, {MouseEventHandler} from "react";
import {
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    List,
    ListItem,
    ListItemText,
    Paper,
    Switch,
    Theme
} from "@material-ui/core";
import {State} from "../store/rootReducer";
import {getIsFetchingSensors, getSensors, getSensorsNextPageLink} from "../store/data/dataSelectors";
import {connect, ConnectedProps} from "react-redux";
import {Sensor} from "../model/SensorData";
import {makeStyles} from "@material-ui/core/styles";
import {thunkFetchSensors} from "../api/apiClient";
import {SensorCompactDataView} from "./SensorDataComponents";
import {selectSensor, setCurrentDataFilter} from "../store/sensor/sensorActions";
import {getIsCurrentDataFilterEnabled, getSelectedSensorId} from "../store/sensor/sensorSelectors";
import {selectQuadrant} from "../store/map/mapActions";
import {hasSensorCurrentData} from "./sensorService";

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    inner: {
        margin: theme.spacing(1),
    },
    sensorWithCurrentData: {
        color: theme.palette.secondary.main,
    },
}));
const mapState = (state: State) => ({
    sensors: getSensors(state),
    hasNextPage: !!getSensorsNextPageLink(state),
    isFetchingSensors: getIsFetchingSensors(state),
    selectedSensorId: getSelectedSensorId(state),
    isCurrentDataFilterEnabled: getIsCurrentDataFilterEnabled(state),
});
const mapDispatch = {
    fetchSensors: thunkFetchSensors,
    selectSensor: selectSensor,
    selectQuadrant: selectQuadrant,
    setCurrentDataFilter: setCurrentDataFilter,
};
const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

interface Props {
    className: string;
}

const SensorListItem = ({sensor, selected, onClick}: { sensor: Sensor, selected: boolean, onClick: MouseEventHandler }) => {
    const styles = useStyles();
    const itemClass = hasSensorCurrentData(sensor) ? styles.sensorWithCurrentData : undefined;
    return <ListItem button onClick={onClick} selected={selected} className={itemClass}>
        <ListItemText
            primary={sensor.label} secondary={<SensorCompactDataView sensorData={sensor.last_event.sensor_data}/>}
        />
    </ListItem>;
};

const SensorListView = ({sensors, hasNextPage, isFetchingSensors, fetchSensors, selectedSensorId, isCurrentDataFilterEnabled, selectQuadrant, selectSensor, setCurrentDataFilter, className}: ReduxProps & Props) => {
    const styles = useStyles();

    const loadNextPage = () => {
        fetchSensors(true);
    };

    const handleSensorClick = (sensorId: string) => () => {
        selectSensor(sensorId);
    };

    const handleBackClick = () => {
        selectQuadrant(null);
    };

    const handleFilterClick = () => {
        setCurrentDataFilter(!isCurrentDataFilterEnabled);
    };

    if (sensors.length === 0) {
        return null;
    }

    const filteredSensors = sensors.filter(it => !isCurrentDataFilterEnabled || hasSensorCurrentData(it));

    return <>
        <Box className={styles.root + ' ' + className}>
            <Paper className={styles.inner}>
                <Button onClick={handleBackClick} variant={'outlined'}>Back</Button>
                <Box padding={2}>
                    <FormControlLabel
                        control={<Switch checked={isCurrentDataFilterEnabled} onChange={handleFilterClick}/>}
                        label={`Current Data (${filteredSensors.length}/${sensors.length})`}
                    />
                </Box>
                <List>
                    {filteredSensors.map(it =>
                        <SensorListItem key={it.id} sensor={it} selected={it.id === selectedSensorId}
                                        onClick={handleSensorClick(it.id)}/>)}
                </List>
                {hasNextPage && <Button onClick={loadNextPage} variant={'outlined'} disabled={isFetchingSensors}>
                    Load more
                    {isFetchingSensors &&
                    <Box clone={true} ml={2}><CircularProgress size={20} thickness={5} color={'inherit'}/></Box>}
                </Button>}
            </Paper>
        </Box>
    </>
};

export default connector(SensorListView);
