import React, {Component} from "react";
import {AppBar, IconButton, Menu, MenuItem, Toolbar, Typography} from "@material-ui/core";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import {connect} from "react-redux";
import {State} from "../store/rootReducer";
import userManager, {signinRedirect} from "../login/userManager";
import {getIsUserLoggedIn} from "../store/oidc/oidcSelectors";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {PATH_API_KEY_ADMIN} from "../App";


interface MenuBarProps {
    isUserLoggedIn: boolean;
}

interface MenuBarDispatch {
}

interface MenuBarState {
    userMenuAnchorsElement: HTMLElement | null
}

class MenuBarComponent extends Component<MenuBarProps & RouteComponentProps, MenuBarState> {

    constructor(props: Readonly<MenuBarProps & RouteComponentProps>) {
        super(props);
        this.state = {userMenuAnchorsElement: null};
    }


    handleOpenUserMenu = (event: any) => {
        this.setState({userMenuAnchorsElement: event.currentTarget});
    };

    handleCloseUserMenu = () => () => {
        this.setState({userMenuAnchorsElement: null});
    };

    handleLogin = (event: any) => {
        this.setState({userMenuAnchorsElement: null});
        signinRedirect(this.props.history.location.pathname);
    };

    handleLogout = (event: any) => {
        this.setState({userMenuAnchorsElement: null});
        userManager.signoutRedirect();
    };

    handleManageApiKeys = (event: any) => {
        this.setState({userMenuAnchorsElement: null});
        this.props.history.push(PATH_API_KEY_ADMIN);
    };

    render() {
        return <AppBar position={'relative'}>
            <Toolbar>
                <div style={{alignItems: 'baseline', display: 'inline-flex'}}>
                    <Typography variant="h5">
                        map433
                    </Typography>
                </div>
                <div style={{flexGrow: 1}}/>
                <IconButton edge="end" color="inherit" onClick={this.handleOpenUserMenu}>
                    <AccountBoxIcon/>
                </IconButton>
            </Toolbar>
            <Menu
                id="menu-appbar"
                anchorEl={this.state.userMenuAnchorsElement}
                getContentAnchorEl={null}
                keepMounted
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={!!this.state.userMenuAnchorsElement}
                onClose={this.handleCloseUserMenu()}
            >
                {this.props.isUserLoggedIn ? null : <MenuItem onClick={this.handleLogin}>Login</MenuItem>}
                {this.props.isUserLoggedIn ? <MenuItem onClick={this.handleLogout}>Logout</MenuItem> : null}
                {this.props.isUserLoggedIn ?
                    <MenuItem onClick={this.handleManageApiKeys}>Manage API Keys</MenuItem> : null}
            </Menu>

        </AppBar>
    }

}

export default connect((state: State): MenuBarProps => ({
    isUserLoggedIn: getIsUserLoggedIn(state),
}), {})(withRouter(MenuBarComponent))
