import React from "react";
import {GraphData} from "./graphDataMapper";
import {LinearYAxis, LinearYAxisTickLabel, LinearYAxisTickSeries, ScatterPlot} from 'reaviz';

interface Props {
    graphData: GraphData;
}

export const TimeSeriesChart = ({graphData}: Props) => {
    if (!graphData) {
        return null;
    }

    return <ScatterPlot
        height={250}
        data={graphData.data}
        yAxis={<LinearYAxis tickSeries={
            <LinearYAxisTickSeries
                label={<LinearYAxisTickLabel
                    format={v => `${v} ${graphData.unit}`}
                />}
            />}
        />}
    />
};
