import {ApiKey} from "../../model/ApiKey";
import {Sensor, SensorEvent, SensorStatisticsItem} from "../../model/SensorData";

export const DATA_FETCHING_API_KEYS = 'DATA_FETCHING_API_KEYS';
export const DATA_FETCHED_API_KEYS = 'DATA_FETCHED_API_KEYS';
export const DATA_FETCHING_SENSOR_STATISTICS = 'DATA_FETCHING_SENSOR_STATISTICS';
export const DATA_FETCHED_SENSOR_STATISTICS = 'DATA_FETCHED_SENSOR_STATISTICS';
export const DATA_FETCHING_SENSORS = 'DATA_FETCHING_SENSORS';
export const DATA_FETCHED_SENSORS = 'DATA_FETCHED_SENSORS';
export const DATA_FETCHING_SENSOR_EVENTS = 'DATA_FETCHING_SENSOR_EVENTS';
export const DATA_FETCHED_SENSOR_EVENTS = 'DATA_FETCHED_SENSOR_EVENTS';

export interface FetchingApiKeysAction {
    type: typeof DATA_FETCHING_API_KEYS;
}

export interface FetchedApiKeysAction {
    type: typeof DATA_FETCHED_API_KEYS;
    payload: Array<ApiKey>;
}

export interface FetchingSensorStatisticsAction {
    type: typeof DATA_FETCHING_SENSOR_STATISTICS;
}

export interface FetchedSensorStatisticsAction {
    type: typeof DATA_FETCHED_SENSOR_STATISTICS;
    payload: Array<SensorStatisticsItem>;
}

export interface FetchingSensorsAction {
    type: typeof DATA_FETCHING_SENSORS;
}

export interface FetchedSensorsAction {
    type: typeof DATA_FETCHED_SENSORS;
    payload: {
        items: Array<Sensor>,
        nextPageLink: string | null,
        append: boolean,
    };
}

export interface FetchingSensorEventsAction {
    type: typeof DATA_FETCHING_SENSOR_EVENTS;
}

export interface FetchedSensorEventsAction {
    type: typeof DATA_FETCHED_SENSOR_EVENTS;
    payload: {
        items: Array<SensorEvent>,
        nextPageLink: string | null,
        append: boolean,
    };
}

export type DataActionTypes =
    FetchingApiKeysAction
    | FetchedApiKeysAction
    | FetchingSensorStatisticsAction
    | FetchedSensorStatisticsAction
    | FetchingSensorsAction
    | FetchedSensorsAction
    | FetchingSensorEventsAction
    | FetchedSensorEventsAction
