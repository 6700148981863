export const SENSOR_SELECT_SENSOR = 'SENSOR_SELECT_SENSOR';
export const SENSOR_SET_CURRENT_DATA_FILTER = 'SENSOR_SET_CURRENT_DATA_FILTER';

export interface SelectSensorAction {
    type: typeof SENSOR_SELECT_SENSOR;
    payload: {
        sensorId: string | null,
    };
}

export interface SetCurrentDataFilterAction {
    type: typeof SENSOR_SET_CURRENT_DATA_FILTER;
    payload: {
        isFilterEnabled: boolean,
    };
}


export type SensorActionTypes = SelectSensorAction | SetCurrentDataFilterAction