import {ActionTypes} from "../actionTypes";
import {ApiKey} from "../../model/ApiKey";
import {
    DATA_FETCHED_API_KEYS, DATA_FETCHED_SENSOR_EVENTS,
    DATA_FETCHED_SENSOR_STATISTICS, DATA_FETCHED_SENSORS,
    DATA_FETCHING_API_KEYS, DATA_FETCHING_SENSOR_EVENTS,
    DATA_FETCHING_SENSOR_STATISTICS, DATA_FETCHING_SENSORS
} from "./dataActionTypes";
import {Sensor, SensorEvent, SensorStatisticsItem} from "../../model/SensorData";

export interface DataState {
    fetchingApiKeys: boolean;
    apiKeys: Array<ApiKey>;
    fetchingSensorStatistics: boolean;
    sensorStatistics: Array<SensorStatisticsItem>;
    fetchingSensors: boolean;
    sensors: Array<Sensor>;
    sensorsNextPageLink: string | null;
    fetchingSensorEvents: boolean;
    sensorEvents: Array<SensorEvent>;
    sensorEventsNextPageLink: string | null;
}

const initialState: DataState = {
    fetchingApiKeys: false,
    apiKeys: [],
    fetchingSensorStatistics: false,
    sensorStatistics: [],
    fetchingSensors: false,
    sensors: [],
    sensorsNextPageLink: null,
    fetchingSensorEvents: false,
    sensorEvents: [],
    sensorEventsNextPageLink: null,
};

export function dataReducer(
    state = initialState,
    action: ActionTypes,
): DataState {
    switch (action.type) {
        case DATA_FETCHING_API_KEYS:
            return {...state, fetchingApiKeys: true};
        case DATA_FETCHED_API_KEYS:
            return {
                ...state,
                apiKeys: action.payload,
                fetchingApiKeys: false,
            };
        case DATA_FETCHING_SENSOR_STATISTICS:
            return {...state, fetchingSensorStatistics: true};
        case DATA_FETCHED_SENSOR_STATISTICS:
            return {
                ...state,
                sensorStatistics: action.payload,
                fetchingSensorStatistics: false,
            };
        case DATA_FETCHING_SENSORS:
            return {...state, fetchingSensors: true};
        case DATA_FETCHED_SENSORS:
            return {
                ...state,
                sensors: action.payload.append ? [...state.sensors, ...action.payload.items] : action.payload.items,
                sensorsNextPageLink: action.payload.nextPageLink,
                fetchingSensors: false,
            };
        case DATA_FETCHING_SENSOR_EVENTS:
            return {...state, fetchingSensorEvents: true};
        case DATA_FETCHED_SENSOR_EVENTS:
            return {
                ...state,
                sensorEvents: action.payload.append ? [...state.sensorEvents, ...action.payload.items] : action.payload.items,
                sensorEventsNextPageLink: action.payload.nextPageLink,
                fetchingSensorEvents: false,
            };
    }
    return state
}
