import {ActionTypes} from "../actionTypes";
import {SENSOR_SELECT_SENSOR, SENSOR_SET_CURRENT_DATA_FILTER} from "./sensorActionTypes";


export const selectSensor = (sensorId: string | null): ActionTypes => ({
    type: SENSOR_SELECT_SENSOR,
    payload: {sensorId},
});

export const setCurrentDataFilter = (isFilterEnabled: boolean): ActionTypes => ({
    type: SENSOR_SET_CURRENT_DATA_FILTER,
    payload: {isFilterEnabled},
});

