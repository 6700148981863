import {
    HumidityValue,
    PressureValue,
    SensorData,
    SensorEvent,
    SensorMetadata,
    TemperatureValue
} from "../model/SensorData";
import Water from "mdi-material-ui/Water";
import Thermometer from "mdi-material-ui/Thermometer";
import Gauge from "mdi-material-ui/Gauge";
import MapMarker from "mdi-material-ui/MapMarker";
import React from "react";
import {Table, TableCell, TableContainer, TableRow} from "@material-ui/core";
import {GeoPosition} from "../model/Geo";
import {formatHumidity, formatPressure, formatTemperature} from "./sensorDataFormatter";

export const PositionView = ({position}: { position: GeoPosition }) => <>
    <MapMarker fontSize={'small'} style={{marginBottom: '-0.2em'}}/>
    {Math.abs(position.latitude)}° {position.latitude >= 0 ? 'N' : 'S'}
    {' '}
    {Math.abs(position.longitude)}° {position.longitude >= 0 ? 'E' : 'W'}
</>;


export const SensorCompactDataView = ({sensorData}: { sensorData: SensorData }) => <>
    {sensorData.temperature ? <TemperatureValueView value={sensorData.temperature}/> : null}
    {sensorData.humidity ? <HumidityValueView value={sensorData.humidity}/> : null}
    {sensorData.air_pressure ? <PressureValueView value={sensorData.air_pressure}/> : null}
    {sensorData.tire_pressure ? <PressureValueView value={sensorData.tire_pressure}/> : null}
</>;

export const HumidityValueView = ({value}: { value: HumidityValue }) => <>
    <Water fontSize={'small'} style={{marginBottom: '-0.2em'}}/>{formatHumidity(value)}{' '}
</>;

export const TemperatureValueView = ({value}: { value: TemperatureValue }) => <>
    <Thermometer fontSize={'small'} style={{marginBottom: '-0.2em'}}/>{formatTemperature(value)}{' '}
</>;

export const PressureValueView = ({value}: { value: PressureValue }) => <>
    <Gauge fontSize={'small'} style={{marginBottom: '-0.2em'}}/>{formatPressure(value)}{' '}
</>;

export const SensorEventDetailView = ({event}: { event: SensorEvent }) =>
    <TableContainer>
        <Table>
            <TableRow>
                <TableCell>Received</TableCell>
                <TableCell>{event.occurred_at.format()}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Position</TableCell>
                <TableCell><PositionView position={event.position}/></TableCell>
            </TableRow>
            {event.sensor_data.temperature ?
                <TableRow>
                    <TableCell>Temperature</TableCell>
                    <TableCell><TemperatureValueView value={event.sensor_data.temperature}/></TableCell>
                </TableRow> : null
            }
            {event.sensor_data.humidity ?
                <TableRow>
                    <TableCell>Humidity</TableCell>
                    <TableCell><HumidityValueView value={event.sensor_data.humidity}/></TableCell>
                </TableRow> : null
            }
            {event.sensor_data.air_pressure ?
                <TableRow>
                    <TableCell>Air Pressure</TableCell>
                    <TableCell><PressureValueView value={event.sensor_data.air_pressure}/></TableCell>
                </TableRow> : null
            }
            {event.sensor_data.tire_pressure ?
                <TableRow>
                    <TableCell>Air Pressure</TableCell>
                    <TableCell><PressureValueView value={event.sensor_data.tire_pressure}/></TableCell>
                </TableRow> : null
            }
        </Table>
    </TableContainer>;

export const SensorMetadataView = ({metadata}: { metadata: SensorMetadata }) =>
    <TableContainer>
        <Table>
            {Object.entries(metadata).map(([key, value]) => (
                <TableRow key={key}>
                    <TableCell>{key}</TableCell>
                    <TableCell>{value.toString()}</TableCell>
                </TableRow>
            ))}
        </Table>
    </TableContainer>;
